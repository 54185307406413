import React, { useEffect } from "react"

import GalleryPage from "../../../components/layout/gallery"
import photos from "../../../media/gallery/city"

const City = () => {
  useEffect(() => {
    for (let i = photos.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[photos[i], photos[j]] = [photos[j], photos[i]]
    }
  })

  return (
    <GalleryPage photos={photos}>
      <div>
        <h2>city</h2>
      </div>
    </GalleryPage>
  )
}

export default City
