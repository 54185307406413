import React, { Fragment } from "react"
import Navbar from "../navigation/navbar"
import Burger from "../navigation/burger"
import ParticlesJS from "./particles"
import SEO from "../SEO"

import GalleryWidget from "../showcase/gallery"

const GalleryPage = props => {
  return (
    <Fragment>
      <SEO pathname="Gallery | City" />
      <div className="gallery-container">
        <Burger />
        <div className="gallery-container-header">
          <div className="gallery-container-header-title">
            <h1>gallery</h1>
            <h2>{props.children}</h2>
          </div>
          <Navbar />
        </div>
        <ParticlesJS />
        <GalleryWidget photos={props.photos} />
      </div>
    </Fragment>
  )
}

export default GalleryPage
